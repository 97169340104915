<template>
  <div v-if="isShow">
    <div class="content">
      <el-form
        label-width="120px"
        :model="chainData"
        ref="chainRef"
        :rules="chainRule"
      >
        <div class="mainTop">
          <el-form-item label="场景类型">
            <el-radio-group v-model="chainData.sceneType">
              <el-radio label="通用场景"></el-radio>
              <el-radio label="行业场景"></el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="场景名称："
            style="width: 470px"
            prop="sceneName"
          >
            <el-input v-model="chainData.sceneName"></el-input>
          </el-form-item>
          <el-form-item label="场景图片：">
            <UploadImage
              @upLoadImgList="lookClick"
              @upOldLoadImgList="saveClick"
              :upLoadImgList.sync="chainData.sceneImgUrl"
              :upOldLoadImgList.sync="chainData.sceneImgList"
            />
          </el-form-item>
          <el-form-item label="行业类别：">
            <!-- <el-cascader
              v-model="chainData.industryLabelList"
              :options="industryThreeOptions"
              :props="industryThreeProps"
              ref="industryThree"
              @change="handleContentChaChange"
              clearable
              collapse-tags
            ></el-cascader> -->
            <div v-for="(item, index) in industrylist" :key="index">
              <el-checkbox-group v-model="item.labelparam1">
                <!-- 显示 typelist -->
                <el-checkbox
                  v-for="o in item.typelist"
                  :label="o.id"
                  :key="o.id"
                  @change="industrycodechange1(o)"
                >
                  <!-- 锚点 -->
                  <span>{{ o.industryName }}</span>
                </el-checkbox>
              </el-checkbox-group>
              <!-- 显示 codechildlist1 -->
              <div class="child_label" v-if="item.codechildlist1.length > 0">
                <span style="color: #7c7f8e; width: 65px; display: inline-block"
                  >二级标签:</span
                >

                <el-checkbox-group v-model="item.labelparam2">
                  <el-checkbox
                    v-for="i in item.codechildlist1"
                    :label="i.id"
                    :key="i.id"
                    @change="industrycodechangechild(i)"
                    >{{ i.industryName }}</el-checkbox
                  >
                </el-checkbox-group>
              </div>
              <div
                style="margin-top: 15px"
                v-if="item.childlist.length > 0"
                class="child_label"
              >
                <span style="color: #7c7f8e; width: 65px; display: inline-block"
                  >三级标签:</span
                >
                <el-checkbox-group v-model="item.labelparam3">
                  <el-checkbox
                    v-for="el in item.childlist"
                    :label="el.id"
                    :key="el.id"
                    @change="industrycodechangechil4(el)"
                    >{{ el.industryName }}</el-checkbox
                  >
                </el-checkbox-group>
              </div>
              <div
                style="margin-top: 15px"
                class="child_label"
                v-if="item.childlist4.length > 0"
              >
                <span style="color: #7c7f8e; width: 65px; display: inline-block"
                  >四级标签:</span
                >
                <el-checkbox-group v-model="item.labelparam4">
                  <el-checkbox
                    v-for="els in item.childlist4"
                    :label="els.id"
                    :key="els.id"
                    @change="industrycodechangechil5(els)"
                    >{{ els.industryName }}</el-checkbox
                  >
                </el-checkbox-group>
              </div>
              <div
                style="margin-top: 15px"
                class="child_label"
                v-if="item.childcode5.length > 0"
              >
                <span style="color: #7c7f8e; width: 65px; display: inline-block"
                  >五级标签:</span
                >
                <el-checkbox-group v-model="item.labelparam5">
                  <el-checkbox
                    v-for="item in item.childcode5"
                    :label="item.id"
                    :key="item.id"
                    @change="industryparamchange5(item)"
                    >{{ item.industryName }}</el-checkbox
                  >
                </el-checkbox-group>
              </div>
              <div
                style="margin-top: 15px"
                class="child_label"
                v-if="item.childcode6.length > 0"
              >
                <span style="color: #7c7f8e; width: 65px; display: inline-block"
                  >六级标签:</span
                >
                <el-checkbox-group v-model="item.labelparam6">
                  <el-checkbox
                    v-for="item in item.childcode6"
                    :label="item.id"
                    :key="item.id"
                    @change="industryparamchange6(item)"
                    >{{ item.industryName }}</el-checkbox
                  >
                </el-checkbox-group>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="所属产业链：">
           
            
           <el-radio-group
             @change="selectIndustrialChain()"
             v-model="chainData.industrialChain"
           >
             <!-- 显示 typelist -->
             <el-radio
               v-for="(o, i) in industrialChainOptions"
               :label="o"
               :key="i"
             >
               <!-- 锚点 -->
               <span>{{ o }}</span>
             </el-radio>
           </el-radio-group>
         </el-form-item>
         <el-form-item label="所属产品：">
			<el-input v-model="chainData.affiliatedProducts"></el-input>
		</el-form-item>
          <el-form-item label="所属行为：">
            <el-checkbox-group v-model="chainData.enterpriseBehaviorLabels">
              <!-- 显示 typelist -->
              <el-checkbox
                v-for="(o, i) in behaviorOptions"
                :label="o.codeName"
                :key="i"
              >
                <!-- 锚点 -->
                <span>{{ o.codeName }}</span>
              </el-checkbox>
            </el-checkbox-group>
            <!-- <el-select
              v-model="chainData.enterpriseBehaviorLabels"
              filterable
              placeholder="请选择"
              multiple
            >
              <el-option
                v-for="(item, index) in behaviorOptions"
                :key="index"
                :label="item.codeName"
                :value="item.codeName"
              >
              </el-option>
            </el-select> -->
            <!-- <el-cascader
              v-model="chainData.enterpriseBehaviorLabels"
              :options="behaviorOptions"
              :props="behaviorProps"
              clearable
              collapse-tags
            ></el-cascader> -->
          </el-form-item>
          
          <el-form-item label="所属生产工艺：">
            <div v-for="(item, index) in radiolist" :key="index">
              <el-checkbox-group v-model="item.labelparam1">
                <!-- 显示 typelist -->
                <el-checkbox
                  v-for="o in item.typelist"
                  :label="o.id"
                  :key="o.id"
                  @change="codechange1(o)"
                >
                  <!-- 锚点 -->
                  <span>{{ o.industrialName }}</span>
                </el-checkbox>
              </el-checkbox-group>
              <!-- 显示 codechildlist1 -->
              <div class="child_label" v-if="item.codechildlist1.length > 0">
                <span style="color: #7c7f8e; width: 65px; display: inline-block"
                  >二级标签:</span
                >

                <el-checkbox-group v-model="item.labelparam2">
                  <el-checkbox
                    v-for="i in item.codechildlist1"
                    :label="i.id"
                    :key="i.id"
                    @change="codechangechild(i)"
                    >{{ i.industrialName }}</el-checkbox
                  >
                </el-checkbox-group>
              </div>
              <div
                style="margin-top: 15px"
                v-if="item.childlist.length > 0"
                class="child_label"
              >
                <span style="color: #7c7f8e; width: 65px; display: inline-block"
                  >三级标签:</span
                >
                <el-checkbox-group v-model="item.labelparam3">
                  <el-checkbox
                    v-for="el in item.childlist"
                    :label="el.id"
                    :key="el.id"
                    @change="codechangechil4(el)"
                    >{{ el.industrialName }}</el-checkbox
                  >
                </el-checkbox-group>
              </div>
              <div
                style="margin-top: 15px"
                class="child_label"
                v-if="item.childlist4.length > 0"
              >
                <span style="color: #7c7f8e; width: 65px; display: inline-block"
                  >四级标签:</span
                >
                <el-checkbox-group v-model="item.labelparam4">
                  <el-checkbox
                    v-for="els in item.childlist4"
                    :label="els.id"
                    :key="els.id"
                    @change="codechangechil5(els)"
                    >{{ els.industrialName }}</el-checkbox
                  >
                </el-checkbox-group>
              </div>
              <div
                style="margin-top: 15px"
                class="child_label"
                v-if="item.childcode5.length > 0"
              >
                <span style="color: #7c7f8e; width: 65px; display: inline-block"
                  >五级标签:</span
                >
                <el-checkbox-group v-model="item.labelparam5">
                  <el-checkbox
                    v-for="item in item.childcode5"
                    :label="item.id"
                    :key="item.id"
                    @change="paramchange5(item)"
                    >{{ item.industrialName }}</el-checkbox
                  >
                </el-checkbox-group>
              </div>
              <div
                style="margin-top: 15px"
                class="child_label"
                v-if="item.childcode6.length > 0"
              >
                <span style="color: #7c7f8e; width: 65px; display: inline-block"
                  >六级标签:</span
                >
                <el-checkbox-group v-model="item.labelparam6">
                  <el-checkbox
                    v-for="item in item.childcode6"
                    :label="item.id"
                    :key="item.id"
                    @change="paramchange6(item)"
                    >{{ item.industrialName }}</el-checkbox
                  >
                </el-checkbox-group>
              </div>
            </div>
          </el-form-item>
       
          <!-- <el-form-item
            label="生产制造类别："
            v-if="
              isShowManufacturingCategory || chainData.manufacturingCategory
            "
          >
            <el-radio-group v-model="chainData.manufacturingCategory">
              <el-radio label="离散型制造业">离散型制造业</el-radio>
              <el-radio label="流程型制造业">流程型制造业</el-radio>
            </el-radio-group>
          </el-form-item> -->
          <el-form-item label="场景描述：">
            <!-- <el-input
              v-model="chainData.sceneDescribe"
              style="width: 80%"
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 8 }"
            ></el-input> -->
            <Editorbar
              v-model="chainData.sceneDescribe"
              :isClear="isClear"
              style="width: 50%"
            />
          </el-form-item>
          <el-form-item label="场景现状：">
            <!-- <el-input
              v-model="chainData.sceneSituation"
              style="width: 80%"
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 8 }"
            ></el-input> -->
             <Editorbar
              v-model="chainData.sceneSituation"
              :isClear="isClear"
              style="width: 50%"
            />
          </el-form-item>
           
          <!-- <el-form-item label="技术方案：">
            <el-input
              v-model="chainData.technicalSolution"
              style="width: 80%"
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 8 }"
            ></el-input>
          </el-form-item> -->
          <el-form-item label="关联企业：">
            <el-input v-model="chainData.associatedCompanyName"></el-input>
          </el-form-item>
          <el-form-item label="相关链接：">
            <el-input v-model="chainData.associatedCompanyUrl"></el-input>
          </el-form-item>
        </div>
      </el-form>
      <div class="ess_people" v-if="personType">
        <div class="ess_people_list">创建人：{{ chainData.createName }}</div>
        <div class="ess_people_list">修改人：{{ chainData.updateName }}</div>
        <div class="ess_people_list">创建时间：{{ chainData.createTimes }}</div>
        <div class="ess_people_list">修改时间：{{ chainData.updateTimes }}</div>
      </div>
    </div>
    <div class="bottom">
      <el-button type="primary" @click="saveItem">保存</el-button>
      <el-button @click="cancelItem" v-if="!personType">取消</el-button>
    </div>
  </div>
</template>

<script>
import DicCheckboxGroup from "@/components/DicCheckboxGroup.vue";
import UploadImage from "../Exercise/ActiveData/uploadImage";
import Editorbar from "@/components/Editorbar";
import {
  insertScenarioBasic,
  selectById,
  updateScenarioBasic,
  getDicRadioButton,
  selectAllIndustrialChainName,
  getAllIndustrialChainName,
  getIndustryThree,
} from "@/api/Sceneknowledge.js";
export default {
  name: "App",
  components: {
    DicCheckboxGroup,
    UploadImage,
    Editorbar
  },
  watch: {
    "chainData.id": {
      handler(newVal) {
        if (this.chainData && newVal) {
          this.isShow = true;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.getDicRadioButton().then(() => {
      this.selectAllIndustrialChainName().then(() => {
        this.getIndustryThree().then(() => {
          setTimeout(() => {
            if (this.personType) {
              this.search().then(() => {
                // this.selectIndustrialChain();
              });
            } else if (!this.personType) {
              this.isShow = true;
            }
          }, 500);
        });
      });
    });
  },
  computed: {
    personData() {
      return Number(this.$route.query.id);
    },
    personType() {
      return Number(this.$route.query.personType);
    },
    Authorization() {
      return sessionStorage.getItem("token");
    },
  },
  data() {
    return {
      isClear: null,
      isShow: false,
      isShowManufacturingCategory: false,
      options: [],
      chainData: {
        sceneName: "", //场景名称
        sceneProperties: "通用场景", //场景属性
        // sceneDescribe: "", //场景描述
        // industryCategoriesList: [], //行业类别
        supplyChainIndustryChild: [],
        sceneImgList: [], //场景图片
        sceneImgUrl: [],
        associatedScenarios: "", //关联场景
        enterpriseBehaviorLabels: [], // 企业行为
        industrialChain: "", //产业链
        industrialChainLabelList: [], //产业链标签
        manufacturingCategory: "", //生产制造类别
        sceneDescribe: "", //生产制造类别
        sceneSituation: "", //生产制造类别
        technicalSolution: "", //生产制造类别
        industryLabelList: [],
      },
      radiolist: [
        {
          typelist: [], //一级标签
          codechildlist1: [],
          childlist: [],
          childlist4: [],
          childcode5: [],
          childcode6: [],
          labelparam1: [],
          labelparam2: [],
          labelparam3: [],
          labelparam4: [],
          labelparam5: [],
          labelparam6: [],
        },
      ],
      //企业行为
      behaviorOptions: [],
      behaviorProps: {
        multiple: true,
        value: "codeName",
        label: "codeName",
        children: "childNode",
      },
      // 产业链行为
      industrialChainOptions: [],
      industrialChainProps: { value: "codeId", label: "codeName" },
      //产业链标签
      industryChainLabelOptions: [],
      industryChainLabelProps: {
        multiple: true,
        value: "id",
        label: "industrialName",
      },
      //行业标签
      industryThreeOptions: [],
      industrylist: [],
      industryThreeProps: {
        multiple: true,
        value: "id",
        label: "industryName",
        children: "childNode",
      },
      chainRule: {
        sceneName: [
          { required: true, message: "请填写场景名称", trigger: "blur" },
        ],
        sceneProperties: [
          { required: true, message: "请选择场景属性", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    lookClick(val) {
      this.chainData.sceneImgUrl = val;
    },
    saveClick(val) {
      this.chainData.sceneImgList = val;
    },
    // 获取企业行为
    async getDicRadioButton() {
      const res = await getDicRadioButton({ code: "046" });
      if (res.code == 200) {
        this.behaviorOptions = res.data;
      }
    },
    // 获取产业链
    async selectAllIndustrialChainName() {
      const res = await selectAllIndustrialChainName();
      if (res.code == 200) {
        this.industrialChainOptions = res.data;
      }
    },
    // 获取产业链标签
    async selectIndustrialChain() {
      this.chainData.industrialChainLabelList = [];
      const res = await getAllIndustrialChainName({
        supplyChainName: this.chainData.industrialChain,
      });
      if (res.code == 200) {
        this.industryChainLabelOptions = res.data.mapTree;
        this.radiolist[0].typelist = this.industryChainLabelOptions;
        // console.log(this.chainData.industrialChainLabelList);
        // this.radiolist[0].labelparam1 = this.chainData.industrialChainLabelList.filter((s) => this.industryChainLabelOptions.some((ele) => ele.id == s));
        // console.log(this.radiolist[0].labelparam1 );
        // this.radiolist = [
        //   {
        //     typelist: this.industryChainLabelOptions, //一级标签
        //     codechildlist1: [],
        //     childlist: [],
        //     childlist4: [],
        //     childcode5: [],
        //     childcode6: [],
        //     labelparam1: [],
        //     labelparam2: [],
        //     labelparam3: [],
        //     labelparam4: [],
        //     labelparam5: [],
        //     labelparam6: [],
        //   },
        // ];
      }

      // this.echolabel()
    },
    codechange1(o) {
      if (!o.checked) {
        if (o.children) {
          this.radiolist[0].codechildlist1 =
            this.radiolist[0].codechildlist1.concat(o.children);
        }
        o.checked = !o.checked;
      } else {
        this.radiolist[0].codechildlist1 =
          this.radiolist[0].codechildlist1.filter(
            (s) =>
              !o.children.some((ele) => ele.industrialName == s.industrialName)
          );
        this.radiolist[0].labelparam1 = this.radiolist[0].labelparam1.filter(
          (s) => !o.children.some((ele) => ele.id == s)
        );
        o.checked = !o.checked;
      }
    },
    codechangechild(o) {
      if (!o.checked) {
        if (o.children) {
          this.radiolist[0].childlist = this.radiolist[0].childlist.concat(
            o.children
          );
        }
        o.checked = !o.checked;
      } else {
        this.radiolist[0].childlist = this.radiolist[0].childlist.filter(
          (s) =>
            !o.children.some((ele) => ele.industrialName == s.industrialName)
        );
        this.radiolist[0].labelparam2 = this.radiolist[0].labelparam2.filter(
          (s) => !o.children.some((ele) => ele.id == s)
        );
        o.checked = !o.checked;
      }
    },
    codechangechil4(o) {
      if (!o.checked) {
        if (o.children) {
          this.radiolist[0].childlist4 = this.radiolist[0].childlist4.concat(
            o.children
          );
        }
        o.checked = !o.checked;
      } else {
        this.radiolist[0].childlist4 = this.radiolist[0].childlist4.filter(
          (s) =>
            !o.children.some((ele) => ele.industrialName == s.industrialName)
        );
        this.radiolist[0].labelparam3 = this.radiolist[0].labelparam3.filter(
          (s) => !o.children.some((ele) => ele.id == s)
        );
        o.checked = !o.checked;
      }
    },
    codechangechil5(o) {
      if (!o.checked) {
        if (o.children) {
          this.radiolist[0].childcode5 = this.radiolist[0].childcode5.concat(
            o.children
          );
        }
        o.checked = !o.checked;
      } else {
        this.radiolist[0].childcode5 = this.radiolist[0].childcode5.filter(
          (s) =>
            !o.children.some((ele) => ele.industrialName == s.industrialName)
        );
        this.radiolist[0].labelparam4 = this.radiolist[0].labelparam4.filter(
          (s) => !o.children.some((ele) => ele.id == s)
        );
        o.checked = !o.checked;
      }
    },
    paramchange5(o) {
      if (!o.checked) {
        if (o.children) {
          this.radiolist[0].childcode6 = this.radiolist[0].childcode6.concat(
            o.children
          );
        }
        o.checked = !o.checked;
      } else {
        this.radiolist[0].childcode6 = this.radiolist[0].childcode6.filter(
          (s) =>
            !o.children.some((ele) => ele.industrialName == s.industrialName)
        );
        this.radiolist[0].labelparam5 = this.radiolist[0].labelparam5.filter(
          (s) => !o.children.some((ele) => ele.id == s)
        );
        o.checked = !o.checked;
      }
    },
    industrycodechange1(o) {
      if (!o.checked) {
        if (o.childNode) {
          this.industrylist[0].codechildlist1 =
            this.industrylist[0].codechildlist1.concat(o.childNode);
        }
        o.checked = !o.checked;
      } else {
        this.industrylist[0].codechildlist1 =
          this.industrylist[0].codechildlist1.filter(
            (s) =>
              !o.childNode.some((ele) => ele.industryName == s.industryName)
          );
        this.industrylist[0].labelparam1 =
          this.industrylist[0].labelparam1.filter(
            (s) => !o.childNode.some((ele) => ele.id == s)
          );
        o.checked = !o.checked;
      }
    },
    industrycodechangechild(o) {
      if (!o.checked) {
        if (o.childNode) {
          this.industrylist[0].childlist =
            this.industrylist[0].childlist.concat(o.childNode);
        }
        o.checked = !o.checked;
      } else {
        this.industrylist[0].childlist = this.industrylist[0].childlist.filter(
          (s) => !o.childNode.some((ele) => ele.industryName == s.industryName)
        );
        this.industrylist[0].labelparam2 =
          this.industrylist[0].labelparam2.filter(
            (s) => !o.childNode.some((ele) => ele.id == s)
          );
        o.checked = !o.checked;
      }
    },
    industrycodechangechil4(o) {
      if (!o.checked) {
        if (o.childNode) {
          this.industrylist[0].childlist4 =
            this.industrylist[0].childlist4.concat(o.childNode);
        }
        o.checked = !o.checked;
      } else {
        this.industrylist[0].childlist4 =
          this.industrylist[0].childlist4.filter(
            (s) =>
              !o.childNode.some((ele) => ele.industryName == s.industryName)
          );
        this.industrylist[0].labelparam3 =
          this.industrylist[0].labelparam3.filter(
            (s) => !o.childNode.some((ele) => ele.id == s)
          );
        o.checked = !o.checked;
      }
    },
    industrycodechangechil5(o) {
      if (!o.checked) {
        if (o.childNode) {
          this.industrylist[0].childcode5 =
            this.industrylist[0].childcode5.concat(o.childNode);
        }
        o.checked = !o.checked;
      } else {
        this.industrylist[0].childcode5 =
          this.industrylist[0].childcode5.filter(
            (s) =>
              !o.childNode.some((ele) => ele.industryName == s.industryName)
          );
        this.industrylist[0].labelparam4 =
          this.industrylist[0].labelparam4.filter(
            (s) => !o.childNode.some((ele) => ele.id == s)
          );
        o.checked = !o.checked;
      }
    },
    industryparamchange5(o) {
      if (!o.checked) {
        if (o.childNode) {
          this.industrylist[0].childcode6 =
            this.industrylist[0].childcode6.concat(o.childNode);
        }
        o.checked = !o.checked;
      } else {
        this.industrylist[0].childcode6 =
          this.industrylist[0].childcode6.filter(
            (s) =>
              !o.childNode.some((ele) => ele.industryName == s.industryName)
          );
        this.industrylist[0].labelparam5 =
          this.industrylist[0].labelparam5.filter(
            (s) => !o.childNode.some((ele) => ele.id == s)
          );
        o.checked = !o.checked;
      }
    },
    async selectIndustrialChains() {
      const res = await getAllIndustrialChainName({
        supplyChainName: this.chainData.industrialChain,
      });
      if (res.code == 200) {
        this.industryChainLabelOptions = res.data.mapTree;

        this.radiolist[0].typelist = this.industryChainLabelOptions;

        this.radiolist[0].labelparam1 =
          this.chainData.industrialChainLabelList.filter((s) =>
            this.industryChainLabelOptions.some((ele) => ele.id == s)
          );

        
        this.industryChainLabelOptions.forEach((o, i) => {
					this.radiolist[0].labelparam1.forEach((el, index) => {
						if (o.id == el) {
							if (o.children) {
								this.radiolist[0].codechildlist1 = this.radiolist[0].codechildlist1.concat(o.children);

								this.radiolist[0].labelparam2 = this.chainData.industrialChainLabelList.filter((s) =>
									this.radiolist[0].codechildlist1.some((ele) => ele.id == s)
								);
								this.radiolist[0].codechildlist1.forEach((a) => {
									this.radiolist[0].labelparam2.forEach((c) => {
										if (a.id == c) {
											if (a.children) {
												this.radiolist[0].childlist = this.radiolist[0].childlist.concat(a.children);
												this.radiolist[0].labelparam3 = this.chainData.industrialChainLabelList.filter((s) =>
													this.radiolist[0].childlist.some((ele) => ele.id == s)
												);

												this.radiolist[0].childlist.forEach((s) => {
													this.radiolist[0].labelparam3.forEach((p) => {
														if (s.id == p) {
															if (s.children) {
																this.radiolist[0].childlist4 = this.radiolist[0].childlist4.concat(s.children);
																this.radiolist[0].labelparam4 = this.chainData.industrialChainLabelList.filter((o) =>
																	this.radiolist[0].childlist4.some((ele) => ele.id == o)
																);
																this.radiolist[0].childlist4.forEach((u) => {
																	this.radiolist[0].labelparam4.forEach((y) => {
																		if (u.id == y) {
																			if (u.children) {
																				this.radiolist[0].childcode5 = this.radiolist[0].childcode5.concat(u.children);
																				this.radiolist[0].labelparam5 = this.chainData.industrialChainLabelList.filter((o) =>
																					this.radiolist[0].childcode5.some((ele) => ele.id == o)
																				);
																				this.radiolist[0].childcode5.forEach((t) => {
																					this.radiolist[0].labelparam5.forEach((n) => {
																						if (t.id == n) {
																							if (t.children) {
																								this.radiolist[0].childcode6 = this.radiolist[0].childcode6.concat(t.children);
																								this.radiolist[0].labelparam6 = this.chainData.industrialChainLabelList.filter((o) =>
																									this.radiolist[0].childcode6.some((ele) => ele.id == o)
																								);
																							}
																						}
																					});
																				});
																			}
																		}
																	});
																});
															}
														}
													});
												});
											}
										}
									});
								});
							}
						}
					});
				});
      }
    },
    //获取行业标签
    async getIndustryThree() {
      const res = await getIndustryThree();
      if (res.code == 200) {
        this.industryThreeOptions = res.data;
        this.industrylist = [
          {
            typelist: this.industryThreeOptions, //一级标签
            codechildlist1: [],
            childlist: [],
            childlist4: [],
            childcode5: [],
            childcode6: [],
            labelparam1: [],
            labelparam2: [],
            labelparam3: [],
            labelparam4: [],
            labelparam5: [],
            labelparam6: [],
          },
        ];
      }
    },
    handleContentChaChange() {
      const data = this.$refs["industryThree"].getCheckedNodes();
      // console.log(data);
      const isShow = data.find((item) => item.path[0] === 154);
      // this.chainData.manufacturingCategory = "离散型制造业";
      if (isShow) {
        this.isShowManufacturingCategory = true;
      } else {
        this.isShowManufacturingCategory = false;
        this.chainData.manufacturingCategory = "";
      }
    },

    async search() {
      const res = await selectById({ id: this.personData });
      if (res.code == 200) {
        // Object.assign(this.chainData, res.data);
        this.chainData = res.data;
        if (!this.chainData.industryLabelList) {
          this.chainData.industryLabelList = [];
        } else {
          let list = [];
          let arr = [];
          this.chainData.industryLabelList.forEach((el, index) => {
            if (el.indexOf(",", 0) != -1) {
              arr = this.chainData.industryLabelList[index].split(",");
              arr.forEach((o, i) => {
                list.push(Number(o));
              });
            } else {
              list.push(Number(el));
            }
          });
          if (list.length) {
            this.chainData.industryLabelList = Array.from(new Set(list));
          }
        
          this.industrylist[0].labelparam1 =
            this.chainData.industryLabelList.filter((s) =>
              this.industryThreeOptions.some((ele) => ele.id == s)
            );
          // this.industryThreeOptions.forEach((o, i) => {
          //   this.industrylist[0].labelparam1.forEach((el, index) => {
          //     if (o.id == el) {
          //       if (o.childNode) {
          //         this.industrylist[0].codechildlist1 =
          //           this.industrylist[0].codechildlist1.concat(o.childNode);
          //         this.industrylist[0].labelparam2 =
          //           this.chainData.industryLabelList.filter((s) =>
          //             o.childNode.some((ele) => ele.id == s)
          //           );
          //         o.childNode.forEach((a) => {
          //           this.industrylist[0].labelparam2.forEach((c) => {
          //             if (a.id == c) {
          //               if (a.childNode) {
          //                 this.industrylist[0].childlist =
          //                   this.industrylist[0].childlist.concat(a.childNode);
          //                 this.industrylist[0].labelparam3 =
          //                   this.chainData.industryLabelList.filter((s) =>
          //                     a.childNode.some((ele) => ele.id == s)
          //                   );
          //                 a.childNode.forEach((s) => {
          //                   this.industrylist[0].labelparam3.forEach((p) => {
          //                     if (s.id == p) {
          //                       if (s.childNode) {
          //                         this.industrylist[0].childlist4 =
          //                           this.industrylist[0].childlist4.concat(
          //                             s.childNode
          //                           );
          //                         this.industrylist[0].labelparam4 =
          //                           this.chainData.industryLabelList.filter(
          //                             (o) =>
          //                               s.childNode.some((ele) => ele.id == o)
          //                           );
          //                       }
          //                     }
          //                   });
          //                 });
          //               }
          //             }
          //           });
          //         });
          //       }
          //     }
          //   });
          // });
          this.industryThreeOptions.forEach((o, i) => {
					this.industrylist[0].labelparam1.forEach((el, index) => {
						if (o.id == el) {
							if (o.childNode) {
								this.industrylist[0].codechildlist1 = this.industrylist[0].codechildlist1.concat(o.childNode);

								this.industrylist[0].labelparam2 = this.chainData.industryLabelList.filter((s) =>
									this.industrylist[0].codechildlist1.some((ele) => ele.id == s)
								);
								this.industrylist[0].codechildlist1.forEach((a) => {
									this.industrylist[0].labelparam2.forEach((c) => {
										if (a.id == c) {
											if (a.childNode) {
												this.industrylist[0].childlist = this.industrylist[0].childlist.concat(a.childNode);
												this.industrylist[0].labelparam3 = this.chainData.industryLabelList.filter((s) =>
													this.industrylist[0].childlist.some((ele) => ele.id == s)
												);

												this.industrylist[0].childlist.forEach((s) => {
													this.industrylist[0].labelparam3.forEach((p) => {
														if (s.id == p) {
															if (s.childNode) {
																this.industrylist[0].childlist4 = this.industrylist[0].childlist4.concat(s.childNode);
																this.industrylist[0].labelparam4 = this.chainData.industryLabelList.filter((o) =>
																	this.industrylist[0].childlist4.some((ele) => ele.id == o)
																);
																this.industrylist[0].childlist4.forEach((u) => {
																	this.industrylist[0].labelparam4.forEach((y) => {
																		if (u.id == y) {
																			if (u.childNode) {
																				this.industrylist[0].childcode5 = this.industrylist[0].childcode5.concat(u.childNode);
																				this.industrylist[0].labelparam5 = this.chainData.industryLabelList.filter((o) =>
																					this.industrylist[0].childcode5.some((ele) => ele.id == o)
																				);
																				this.industrylist[0].childcode5.forEach((t) => {
																					this.industrylist[0].labelparam5.forEach((n) => {
																						if (t.id == n) {
																							if (t.childNode) {
																								this.industrylist[0].childcode6 = this.industrylist[0].childcode6.concat(t.childNode);
																								this.industrylist[0].labelparam6 = this.chainData.industryLabelList.filter((o) =>
																									this.industrylist[0].childcode6.some((ele) => ele.id == o)
																								);
																							}
																						}
																					});
																				});
																			}
																		}
																	});
																});
															}
														}
													});
												});
											}
										}
									});
								});
							}
						}
					});
				});
        }
        if (!this.chainData.industrialChainLabelList) {
          this.chainData.industrialChainLabelList = [];
        } else {
          let list = [];
          let arr = [];
          this.chainData.industrialChainLabelList.forEach((el, index) => {
            if (el.indexOf(",", 0) != -1) {
              arr = this.chainData.industrialChainLabelList[index].split(",");
              arr.forEach((o, i) => {
                list.push(Number(o));
              });
            } else {
              list.push(Number(el));
            }
          });
          if (list.length) {
            this.chainData.industrialChainLabelList = Array.from(new Set(list));
          }
          this.selectIndustrialChains();
        }
        if (this.chainData.updateTime) {
          this.chainData.updateTimes = this.timestampToTime(
            this.chainData.updateTime
          );
        }
        if (this.chainData.createTime) {
          this.chainData.createTimes = this.timestampToTime(
            this.chainData.createTime
          );
        }
        if (!this.chainData.enterpriseBehaviorLabels) {
          this.chainData.enterpriseBehaviorLabels = [];
        }
        // if (!this.chainData.industrialChainLabelList) {
        //   this.chainData.industrialChainLabelList = [];
        // } else {
        //   this.chainData.industrialChainLabelList = this.chainData.industrialChainLabelList.map(
        //     (item, index) => {
        //       return item.split(",");
        //     }
        //   );
        // }
        // if (!this.chainData.industryLabelList) {
        //   this.chainData.industryLabelList = [];
        // } else {
        //   this.chainData.industryLabelList = this.chainData.industryLabelList.map(
        //     (item, index) => {
        //       return item.split(",");
        //     }
        //   );
        // }
        if (!this.chainData.temporaryProductImg) {
          this.chainData.sceneImgList = [];
        } else {
          this.chainData.sceneImgUrl = this.chainData.temporaryProductImg;
        }
        if (!this.chainData.temporaryProductImg) {
          this.chainData.sceneImgUrl = [];
        }
      } else {
        this.$message.error("查询失败");
      }
    },
    saveItem() {
      // console.log(this.radiolist);

      Object.assign(this.chainData, {
        Authorization: this.Authorization,
      });
      let res;
      this.$refs.chainRef.validate(async (valid) => {
        //async
        if (valid) {
          this.chainData.industryLabelList = [
            ...this.industrylist[0].labelparam1,
            ...this.industrylist[0].labelparam2,
            ...this.industrylist[0].labelparam3,
            ...this.industrylist[0].labelparam4,
            ...this.industrylist[0].labelparam5,
            ...this.industrylist[0].labelparam6,
          ];

          this.chainData.industrialChainLabelList = [
            ...this.radiolist[0].labelparam1,
            ...this.radiolist[0].labelparam2,
            ...this.radiolist[0].labelparam3,
            ...this.radiolist[0].labelparam4,
            ...this.radiolist[0].labelparam5,
            ...this.radiolist[0].labelparam6,
          ];

          // if (!typeof this.chainData.industryLabelList[0] === "string") {
          // this.chainData.industryLabelList =
          //   this.chainData.industryLabelList.map((item, index) => {
          //     return item.join(",");
          //   });
          // }
          // if (
          //   !typeof this.industrialChainLabelList.industryLabelList[0] ===
          //   "string"
          // ) {
          // this.chainData.industrialChainLabelList =
          //   this.chainData.industrialChainLabelList.map((item, index) => {
          //     return item.join(",");
          //   });
          // }
          if (!this.personType) {
            res = await insertScenarioBasic(this.chainData);
            if (res.code === 200) {
              this.$message.success("新增成功");
              this.$router.go(-1);
            } else {
              this.$message.error(res.msg);
            }
          } else {
            res = await updateScenarioBasic(this.chainData);
            if (res.code === 200) {
              this.$message.success("编辑成功");
              this.$router.go(-1);
            }
          }
        }
      });
    },
    cancelItem() {
      this.$router.go(-1);
    },
    handleClose(tag) {
      const index = this.chainData.fatherIds.indexOf(tag);
      this.chainData.fatherIds.splice(index, 1);
    },
    add0(m) {
      return m < 10 ? "0" + m : m;
    },
    timestampToTime(timestamp) {
      var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000

      var Y = date.getFullYear() + "-";

      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";

      var D = date.getDate();

      var h = date.getHours();

      var m = date.getMinutes();

      var s = date.getSeconds();

      return (
        Y +
        M +
        this.add0(D) +
        " " +
        this.add0(h) +
        ":" +
        this.add0(m) +
        ":" +
        this.add0(s)
      ); //时分秒可以根据自己的需求加上
    },
    updateCode(val, tag) {
      switch (tag) {
        case "领域":
          this.chainData.supplyChainIndustryChild = val;
          break;

        case "行业":
          this.chainData.supplyChainIndustryChild = val;
          break;

        case "技术":
          this.chainData.supplyChainIndustryChild = val;
          break;

        default:
          break;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.el-radio {
  color: #606266;
  cursor: pointer;
  margin-right: 30px;
  margin-bottom: 10px;
  margin-top: 10px;
}
svg {
  font-size: 14px;
}
.mainTop {
  background-color: white;
  padding: 20px 20px 0 20px;
  // margin-bottom: 20px;
}

.content {
  overflow: auto;
  width: 100%;
  background-color: #fff;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px; /*滚动条的圆角*/
  background-color: grey; /*滚动条的背景颜色*/
}
.ess_people {
  display: flex;
  flex-direction: column;
  // overflow: hidden;
  margin: 20px 30px;
  align-content: center;

  .ess_people_list {
    width: 100%;
    margin: 4px 0;
    color: #7c7f8e;
    font-size: 12px;
  }
}
// 上传
/deep/ .el-upload--picture-card {
  width: 200px !important;
  height: 150px !important;
  line-height: 150px !important;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 200px;
  height: 150px;
  // line-height: 150px;
}
/deep/ .el-upload {
  background-color: #fff;
}
/deep/.el-input {
  width: 470px !important;
}
// /deep/.el-textarea {
//   width: 80% !important;
//   // min-height: 40px !important;
// }
/deep/ .el-icon-circle-plus-outline {
  font-size: 31px;
  vertical-align: middle;
  color: #698eef;
  cursor: pointer;
  padding-left: 10px;
}
.bottomStyle {
  /deep/ .el-form-item {
    margin-bottom: 5px;
  }
  /deep/ .el-upload--picture-card {
    width: 150px !important;
    height: 100px !important;
    line-height: 100px !important;
  }
  /deep/ .el-upload-list--picture-card .el-upload-list__item {
    width: 150px;
    height: 100px;
    // line-height: 150px;
  }
  /deep/ .el-upload {
    background-color: #fff;
  }
}
/deep/ .el-icon-remove-outline {
  font-size: 31px;
  vertical-align: middle;
  color: red;
  cursor: pointer;
  padding-left: 10px;
}
.flexStyle {
  display: flex;
  margin: 10px 10px 10px 0;
}
.bottom {
  border-top: 1px solid #dbdbdb;
  background: #fff;
  padding: 20px 20px;
  display: flex;
  justify-content: center;
  .el-button {
    margin: 0 20px;
  }
}
/deep/ .el-tag.el-tag--info {
  background: rgba(30, 159, 255, 0.2);
  color: rgb(30, 159, 255);
}
/deep/ .el-tag.el-tag--info .el-tag__close {
  color: rgb(30, 159, 255);
  background: rgba(30, 159, 255, 0.2);
}
</style>
